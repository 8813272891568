<template>
    <div class="positions">
        <Block />
        <SavedModal v-if="saved == true" @close="closeModal"/>
        <DeleteModal v-if="deleted == true"  @close="closeModal" />
        <div class="positions__right">
            <div class="positions__right__top">
                <div class="positions__right__top__title">
                    <p>Позиции  </p>
                </div>
                <div class="positions__right__top__time">
                    <p>{{$store.state.currentTime}}</p>
                    <p style="font-size: 12px;">{{$store.state.currentDate}}</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="positions__right__name">
                <p>Позиция</p>
                <button @click="open = true" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    <p>Добавить</p>
                </button>
            </div>
            <div class="positions__right__add" v-if="open == true">
                <div class="positions__right__add__top">
                    <div>
                        <input type="text" placeholder="Введите название" v-model="v$.title.$model"/>
                        <template v-for="(error) of v$.title.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="positions__right__add__top__right">
                        <button @click="sendData">Сохранить</button>
                        <img src="@/assets/icons/exit.svg" @click="open = false" style="cursor: pointer;"/>
                    </div>
                </div>
            </div>
            <div class="positions__right__values">
                <div class="positions__right__values__in" v-for="item in newPositions" :key="item">
                    <div class="positions__right__values__in__top">
                        <div class="positions__right__values__in__top__left">
                            <p>{{ item.title }}</p>
                        </div>
                        <div class="positions__right__values__in__top__right">
                            <img src="@/assets/icons/exit.svg" @click="deleteData(item.id)" style="cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'"/>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric } from '@vuelidate/validators'
import axios from "axios";
import DeleteModal from '@/components/DeleteModal.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    components: {
        Block, SavedModal, DeleteModal
    },
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    data(){
        return{
            newPositions: false,
            open: false,
            title: '',
            saved: false,
            deleted: false
        }
    },
    validations() {
    return {
        title: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(3)),
            }
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        async getPage() {    
            await this.$axios.get(`positions/get`,
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newPositions = res.data.data
            })
            .catch(err => {
            })     
        },
        async sendData() {
        if (!this.v$.$invalid){
            const back = {
                title: this.title,
            };
            try {
                const response = await axios.post("https://api-ems.mydev.kz/api/positions/create", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 200) {
                    // Добавить новую группу в массив после успешного ответа
                    this.getPage();
                    // Сбросить поле ввода
                    this.title = '';
                    this.saved = true;
                    this.open = false
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.v$.$touch();
        }
    },
    deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/positions/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Успешно удалено!')
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
    .line{
    border: 1px solid #0000001A;
}
.positions{
        width: 100%;
        display: flex;
        justify-content: end;
        &__right{
            padding: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__name{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #00000040;
                border: 1px solid #0000001A;
                padding: 15px 72px;
                border-radius: 5px;
                button{
                    background: inherit;
                    padding: 5px 37px;
                    border-radius: 10px;
                    border: 1px solid #0000001A;
                    color: #1965EA;
                    cursor: pointer;
                }
            }
            &__values{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 72px;
                        &__right{
                            display: flex;
                            gap: 20px;
                            align-items: center;
                            color: #1965EA;
                            img{
                            cursor: pointer;
                        }
                        }
                        &__mid{
                            color: #00000080;
                        }
                        &__left{
                            color: #00000080;
                        }
                    }
                }
            }
            &__add{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 72px;
                    &__right{
                        display: flex;
                        align-items: center;
                        text-align: center;
                        gap: 20px;
                    }
                    input{
                        border: none;
                        outline: none;
                    }
                    button{
                        border: none;
                        background: inherit;
                        color: green;
                        cursor: pointer;
                    }
                }
            }

        }
    }
</style>
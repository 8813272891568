<template>
    <div class="companies">
        <Block />
        <SavedModal v-if="saved == true" @close="closeModal"/>
        <DeleteModal v-if="deleted == true"  @close="closeModal" />
        <div class="companies__right">
            <div class="companies__right__top">
                <div class="companies__right__top__title">
                    <p>Компании</p>
                </div>
                <div class="companies__right__top__time">
                    <p>{{$store.state.currentTime}}</p>
                    <p style="font-size: 12px;">{{$store.state.currentDate}}</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="companies__right__name">
                <div>
                    <p>Название компании</p>
                </div>
                <div>
                    <p>БИН</p>
                </div>
                <div>
                    <p>Аквапарк</p>
                </div>
                <div>
                    <button @click="open = true" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    Добавить
                    </button>
                </div>
            </div>
            <div class="companies__right__add" v-if="open == true">
                <div class="companies__right__add__top">
                    <div>
                        <input type="text" placeholder="Введите название" v-model="v$.name.$model"/>
                        <template v-for="(error) of v$.name.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div>
                        <input type="text" placeholder="Введите БИН" v-model="v$.bin.$model"/>
                        <template v-for="(error) of v$.bin.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div>
                        <select v-model="v$.branch.$model">
                            <option value="" disabled selected hidden>Выберите Аквапарк</option>
                            <option v-for="(item, idx) in newBranches" :key="idx" :value="item">
                                {{ item.title }}
                            </option>
                        </select>
                        <template v-for="(error) of v$.branch.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="companies__right__add__top__right">
                        <button @click="sendData">Сохранить</button>
                        <img src="@/assets/icons/exit.svg" @click="open = false" style="cursor: pointer;"/>
                    </div>
                </div>
                <div class="line"></div>
            </div>
            <div class="companies__right__values">
                <div class="companies__right__values__in" v-for="item in newCompanies" :key="item">
                    <div class="companies__right__values__in__top">
                        <div class="companies__right__values__in__top__left">
                            <input type="text" :placeholder="item.title" :disabled="editableItemId !== item.id" v-model="item.title"/>
                        </div>
                        <div class="companies__right__values__in__top__mid">
                            <input type="text" :placeholder="item.bin" :disabled="editableItemId !== item.id" v-model="item.bin"/>
                        </div>
                        <div class="companies__right__values__in__top__right">
                            <input type="text" :disabled="editableItemId !== item.id" :v-model="item?.branch?.title" v-if="editableItemId !== item.id" :placeholder="item?.branch?.title"/>
                            <select v-model="justBranch" v-if="editableItemId == item.id">
                                <option value="" disabled selected hidden>Выберите новый аквапарк</option>
                                <option v-for="(branch, idx) in newBranches" :key="branch" :value="branch.id">
                                    {{ branch.title }}
                                </option>
                            </select>
                        </div>
                        <div class="companies__right__values__in__top__right">
                            <button v-if="editableItemId == item.id" @click="updateData(item)">Сохранить</button>
                            <img v-if="editableItemId == item.id" src="@/assets/icons/exit.svg" @click="toggleEdit(item.id)"/>
                            <img src="@/assets/icons/edit.svg" @click="toggleEdit(item.id)" v-if="editableItemId !== item.id"/>
                            <p @click="deleteData(item.id)" style="color: rgb(173, 62, 62); cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' && editableItemId !== item.id">delete</p>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import axios from "axios";
import DeleteModal from '@/components/DeleteModal.vue';
import { toast } from 'vue3-toastify';
export default {
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    components: {
        Block, SavedModal, DeleteModal
    },
    data(){
        return{
            open: false,
            name: '',
            editableItemId: null,
            bin: '',
            branch: '',
            newCompanies: false,
            newBranches: false,
            justBranch: '',
            saved: false,
            deleted: false,
            companies: [
                {
                    name: 'Компания'
                },
                {
                    name: 'Компания'
                },
                {
                    name: 'Компания'
                },
                {
                    name: 'Компания'
                },
            ]
        }
    },
    validations() {
    return {
        name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(3)),
            },
            branch: {
                required: helpers.withMessage('Обязательное поле', required),
            },
        bin: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Допустимое значение: 12', minLength(12)),
                maxLength: helpers.withMessage('Допустимое значение: 12', maxLength(12)),
                numeric: helpers.withMessage('Только цифры', numeric),
            },
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        toggleEdit(itemId) {
            this.editableItemId = this.editableItemId === itemId ? null : itemId;
        },
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        updateData(value){
            const back = {
                id: value.id,
                title: value.title,
                bin: value.bin,
                branch_id: this.justBranch
            };
            axios.post("https://api-ems.mydev.kz/api/companies/update", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        window.location.reload(true);
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
        async getPage() {    
            await this.$axios.get(`companies/get`,
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newCompanies = res.data.data
            })
            .catch(err => {
            })     
            
            await this.$axios.get(`branches/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newBranches = res.data.data
            })
            .catch(err => {
            }) 
        },
        async sendData() {
        if (!this.v$.$invalid){
            const back = {
                title: this.name,
                bin: this.bin,
                branch_id: this.branch.id
            };
            try {
                const response = await axios.post("https://api-ems.mydev.kz/api/companies/create", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 200) {
                    // Добавить новую группу в массив после успешного ответа
                    this.getPage()
                    // Сбросить поле ввода
                    this.name = '';
                    this.bin = '';
                    this.saved = true;
                    this.open = false
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.v$.$touch();
        }
    },
    deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/companies/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        toast.error('Нельзя удалять привязанную компанию')
                      }
                    })
                    .catch((e) => { 
                        toast.error('Нельзя удалять привязанную компанию')
                    });
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.line{
    border: 1px solid #0000001A;
}
    .companies{
        width: 100%;
        display: flex;
        justify-content: end;
        &__right{
            padding: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__name{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #00000040;
                border: 1px solid #0000001A;
                padding: 15px 72px;
                border-radius: 5px;
                div{
                    width: 33%;
                    display: flex;
                    justify-content: center;
                }
                button{
                    background: inherit;
                    padding: 5px 37px;
                    border-radius: 10px;
                    border: 1px solid #0000001A;
                    color: #1965EA;
                    cursor: pointer;
                }
            }
            &__values{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__top{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 72px;
                        input{
                            outline: none;
                            text-align: center;
                            border-radius: 10px;
                            background: inherit;
                        }
                        &__mid{
                            color: #00000080;
                            width: 33%;
                            justify-content: center;
                            display: flex;
                        }
                        &__right{
                            color: #00000080;
                            width: 33%;
                            justify-content: center;
                            display: flex;
                            gap: 20px;
                            img{
                                cursor: pointer;
                            }
                            button{
                            background: inherit;
                            padding: 5px 20px;
                            border-radius: 10px;
                            border: 1px solid #0000001A;
                            color: #1965EA;
                            cursor: pointer;
                            }
                            select{
                                    color: #1965EA;
                                    padding: 5px 10px;
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid #0000001A;
                                    border-radius: 10px;
                                    outline: none;
                                }
                        }
                        &__left{
                            color: #00000080;
                            display: flex;
                            width: 33%;
                            justify-content: center;
                        }
                    }
                }
            }
            &__add{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 72px;
                    div{
                        width: 33%;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                    }
                    &__right{
                        display: flex;
                        align-items: center;
                        flex-direction: row !important;
                        text-align: center;
                        gap: 20px;
                    }
                    input{
                        border: none;
                        outline: none;
                    }
                    select{
                        border: none;
                        outline: none;
                        border-bottom: 1px solid black !important;
                        padding: 5px;
                        width: 90%;
                    }
                    button{
                        border: none;
                        background: inherit;
                        color: green;
                        cursor: pointer;
                    }
                }
            }

        }
    }
</style>
<template>
    <div class="groups">
        <SavedModal v-if="saved == true" @close="closeModal" />
        <DeleteModal v-if="deleted == true" @close="closeModal"/>
        <Block />
        <div class="groups__right">
            <div class="groups__right__top">
                <div class="groups__right__top__title">
                    <p>Подразделения</p>
                </div>
                <div class="groups__right__top__time">
                    <p>{{$store.state.currentTime}}</p>
                    <p style="font-size: 12px;">{{$store.state.currentDate}}</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="groups__right__name">
                <p>Название подразделении</p>
                <button @click="open = true" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    Добавить
                </button>
            </div>
            <div class="groups__right__values">
                <div class="groups__right__values__in" v-for="item in newGroups" :key="item">
                    <div class="groups__right__values__in__top">
                        <div class="groups__right__values__in__top__left">
                            <input type="text" :placeholder="item.name" v-model="item.name" disabled/>
                        </div>
                        <div class="groups__right__values__in__top__right">
                            <p style="cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'" @click="openEditmethod(item)">Изменить</p>
                            <img src="@/assets/icons/exit.svg" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'" @click="deleteData(item.id)" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="groups__addBack" v-if="open == true" @click="open = false">
            <div class="groups__add" @click.stop>
                <div class="groups__add__name">
                <p>Введите название группы</p>
                <input placeholder="Название" v-model="v$.name.$model"/>
                <template v-for="(error) of v$.name.$errors" :key="error">
                    <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <p style="color: #1965EA; font-size: 16px;">Выберите отделы</p>
            <div class="groups__add__deps">
                <div class="groups__add__deps__in" v-for="item in newDepartments" :key="item.id">
                    <input type="checkbox" :value="item.id" v-model="v$.department_item_ids.$model"/>
                    <p>{{ item.title }}</p>
                </div>
                <template v-for="(error) of v$.department_item_ids.$errors" :key="error">
                    <p class="errorValid">{{ error.$message }}</p>
                </template>
            </div>
            <div class="groups__add__buttons">
                <button class="groups__add__buttons__save" @click="sendData()">Сохранить</button>
                <button class="groups__add__buttons__all" @click="selectAll">Выбрать все</button>
            </div>
            <img src="@/assets/icons/exit.svg" class="exit" @click="open = false"/>
            </div>
        </div>
        <div class="groups__editBack" v-if="openEdit == true" @click="openEdit = false">
            <div class="groups__edit" @click.stop>
                <div class="groups__edit__name">
                <p>Название</p>
                <input placeholder="Введите новое название" v-model="selectedName"/>
            </div>
            <p style="color: #1965EA; font-size: 16px;">Добавьте новые отделы</p>
            <div class="groups__edit__deps">
                <div class="groups__edit__deps__in" v-for="item in newDepartments" :key="item.id">
                    <input type="checkbox" :value="item.id" v-model="selectedDepartments"/>
                    <p>{{ item.title }}</p>
                </div>
            </div>
            <div class="groups__edit__buttons">
                <button class="groups__edit__buttons__save" @click="updateData()">Изменить</button>
                <button class="groups__edit__buttons__all" @click="selectedAllDepartments">Выбрать все</button>
            </div>
            <img src="@/assets/icons/exit.svg" class="exit" @click="openEdit = false"/>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric } from '@vuelidate/validators'
import axios from "axios";
import { toast } from 'vue3-toastify';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        Block,
        SavedModal, 
        DeleteModal
    },
    data(){
        return{
            saved: false,
            name: '',
            openEdit: false,
            open: false,
            newGroups: false,
            deleted: false,
            department_item_ids: [],
            department: '',
            newDepartments: false,
            selectedDepartments: [],
            selectedName: '',
            selectedId: '',
            newName: '',
            groups: [
                {
                    name: 'Группа'
                },
                {
                    name: 'Группа'
                },
                {
                    name: 'Группа'
                },
                {
                    name: 'Группа'
                },
            ]
        } 
    },
    validations() {
    return {
        name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(3)),
            },
            department_item_ids: {
                required: helpers.withMessage('Выберите хотя бы один отдел', required),
            },
        }
    },
    async created() {
        this.getPage()    
        this.getDeps()
    },
    methods:{
        openEditmethod(item){
            this.openEdit = true
            this.selectedName = item.name
            this.selectedDepartments = item.departments.map(dep => dep.id);
            this.selectedId = item.id;
        },
        selectAll() {
            this.department_item_ids = this.newDepartments.map((item) => item.id);
        },
        selectedAllDepartments(){
            this.selectedDepartments = this.newDepartments.map((item) => item.id);
        },
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        async getDeps(){
            await this.$axios.get(`departments/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newDepartments = res.data.data
            })
            .catch(err => {
            }) 
        },
        async getPage() {    
            await this.$axios.get(`groups/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newGroups = res.data.data
            })
            .catch(err => {
            })     
        },
        sendData(){
            if (!this.v$.$invalid){
                const back = {
                    name: this.name,
                    departments: this.department_item_ids.join()
                };
                axios
                .post("https://api-ems.mydev.kz/api/groups/create", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.open = false
                        toast('Успешно сохранено!')
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
            }
            else{
            this.v$.$touch();
            }
        },
        updateData(){
                const back = {
                    id: this.selectedId,
                    name: this.selectedName,
                    departments: this.selectedDepartments.join()
                };
                axios
                .post("https://api-ems.mydev.kz/api/groups/update", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Успешно изменено!')
                        this.openEdit = false
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
    deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/groups/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red !important;
  font-size: 12px !important;
  top: -10px !important;
}
.exit{
    position: absolute;
    top: 3%;
    right: 3%;
     cursor: pointer;
}
.line{
    border: 1px solid #0000001A;
}
    .groups{
        width: 100%;
        display: flex;
        justify-content: end;
        &__right{
            padding: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__name{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 4px 4px 0px #00000040;
                border: 1px solid #0000001A;
                padding: 15px 72px;
                border-radius: 5px;
                button{
                    background: inherit;
                    padding: 5px 37px;
                    border-radius: 10px;
                    border: 1px solid #0000001A;
                    color: #1965EA;
                    cursor: pointer;
                }
            }
            &__values{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    input{
                        border: none;
                        outline: none;
                        background: transparent;
                    }
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 72px;
                        div{
                            width: 33%;
                        }
                        &__right{
                            display: flex;
                            gap: 20px;
                            align-items: center;
                            color: #1965EA;
                            justify-content: end;
                            button{
                                border: none;
                                background: inherit;
                                color: green;
                                cursor: pointer;
                            }
                        }
                        &__center{
                            display: flex;
                            justify-content: center;
                            select{
                                border: none;
                                outline: none;
                            }
                        }
                        &__left{
                            color: #00000080;
                        }
                    }
                }
            }
            &__add{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 72px;
                    &__right{
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        img{
                            cursor: pointer;
                        }
                    }
                    input{
                        border: none;
                        outline: none;
                        background: transparent;
                    }
                    select{
                        border: none;
                        outline: none;
                    }
                    button{
                        border: none;
                        background: inherit;
                        color: green;
                        cursor: pointer;
                    }
                }
            }

        }
        &__addBack{
            position: fixed;
            z-index: 998;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(192, 177, 177, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__add{
            width: 30%;
            position: relative;
            gap: 30px;
            padding: 30px 50px;
            background: white;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            gap: 20px;
            &__name{
                display: flex;
                flex-direction: column;
                gap: 10px;
                input{
                    width: 60%;
                    border: 1px solid #00000026;
                    outline: none;
                    padding: 10px;
                    border-radius: 10px;
                    &::placeholder{
                        color: grey;
                    }
                }
                p{
                    font-size: 16px;
                    color: #1965EA;
                }
            }
            &__deps{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                row-gap: 20px;
                &__in{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    width: 45%;
                }
            }
            &__buttons{
                display: flex;
                gap: 20px;
                &__save{
                    padding: 8px 30px;
                    font-size: 14px;
                    color: #1b912a;
                    border: 1px solid #1b912a;
                    border-radius: 10px;
                    background: transparent;
                    cursor: pointer;
                }
                &__all{
                    padding: 8px 30px;
                    font-size: 14px;
                    color: #1965EA;
                    border: 1px solid #1965EA;
                    border-radius: 10px;
                    background: transparent;
                    cursor: pointer;
                }
            }
        }
        &__editBack{
            position: fixed;
            z-index: 998;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(192, 177, 177, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__edit{
            width: 30%;
            position: relative;
            gap: 30px;
            padding: 30px 50px;
            background: white;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            gap: 20px;
            &__name{
                display: flex;
                flex-direction: column;
                gap: 10px;
                input{
                    width: 60%;
                    border: 1px solid #00000026;
                    outline: none;
                    padding: 10px;
                    border-radius: 10px;
                    &::placeholder{
                        color: grey;
                    }
                }
                p{
                    font-size: 16px;
                    color: #1965EA;
                }
            }
            &__deps{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                row-gap: 20px;
                &__in{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    width: 45%;
                }
            }
            &__buttons{
                display: flex;
                gap: 20px;
                &__save{
                    padding: 8px 30px;
                    font-size: 14px;
                    color: #1b912a;
                    border: 1px solid #1b912a;
                    border-radius: 10px;
                    background: transparent;
                    cursor: pointer;
                }
                &__all{
                    padding: 8px 30px;
                    font-size: 14px;
                    color: #1965EA;
                    border: 1px solid #1965EA;
                    border-radius: 10px;
                    background: transparent;
                    cursor: pointer;
                }
            }
        }
    }
</style>
<template>
    <div class="absence">
        <Block />
        <SavedModal v-if="saved == true" @close="closeModal"/>
        <DeleteModal v-if="deleted == true"  @close="closeModal" />
        <div class="absence__block">
            <div class="absence__block__top">
                <div class="absence__block__top__title">
                    <p>Отсуствия</p>
                </div>
                <div class="absence__block__top__right" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'">
                    <p style="cursor: pointer;" @click="openModal = true">Добавить</p>
                </div>
            </div>
            <div class="absence__block__name">
                <div>
                    <p>Имя</p>
                </div>
                <div>
                    <p>Короткое имя</p>
                </div>
                <div>
                    <p>Цвет</p>
                </div>
                <div>
                </div>
            </div>
            <div class="absence__block__add" v-if="openModal == true">
                <div class="absence__block__add__name">
                    <input type="text" placeholder="Введите название" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="absence__block__add__name">
                    <input type="text" placeholder="Выберите букву" v-model="v$.type.$model"/>
                    <template v-for="(error) of v$.type.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="absence__block__add__color">
                    <input type="color" placeholder="Выберите цвет" v-model="v$.color.$model"/>
                    <template v-for="(error) of v$.color.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="absence__block__add__button">
                    <button @click="sendData()" style="cursor: pointer;">
                        Сохранить
                    </button>
                    <img src="@/assets/icons/exit.svg" @click="openModal = false" style="cursor: pointer;" />
                </div>  
            </div>
            <div class="line"></div>
            <div class="absence__block__table">
                <div class="absence__block__table__in" v-for="item in absences" :key="item">
                    <div class="absence__block__table__in__top">
                        <div class="absence__block__table__in__top__name">
                            {{ item.title }}
                        </div>
                        <div class="absence__block__table__in__top__letter">
                            {{ item.type }}
                        </div>
                        <div class="absence__block__table__in__top__color">
                            <div class="absence__block__table__in__top__color__in" :style="{background: item.color}">

                            </div>
                        </div>
                        <div class="absence__block__table__in__top__change">
                            <img src="@/assets/icons/exit.svg" style="cursor: pointer;" @click="deleteDate(item.id)" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr'"/>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import SavedModal from '@/components/SavedModal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import axios from "axios";
import DeleteModal from '@/components/DeleteModal.vue';
export default {
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    components:{
        Block, SavedModal, DeleteModal
    },
    data(){
        return{
            changes:[
                {
                    name:'Сотрудник заболел',
                    letter:'З'
                },
                {
                    name:'Сотрудник в отпуске',
                    letter:'О'
                },
                {
                    name:'Сотрудник на удаленке',
                    letter:'У'
                },
                {
                    name:'Сотрудник на удаленке',
                    letter:'У'
                },
                {
                    name:'Сотрудник на удаленке',
                    letter:'У'
                },
            ],
            absences: false,
            openModal: false,
            name: '',
            type: '',
            color: '',
            saved: false,
            deleted: false,
        }
    }, 
    validations() {
    return {
        name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(3)),
            },
        type: {
                required: helpers.withMessage('Обязательное поле', required),
                maxLength: helpers.withMessage('Максимально только 2 значения', maxLength(2)),
            },
        color: {
            required: helpers.withMessage('Обязательное поле', required),
        },
        }
    },
    async created() {
        this.getPage()    
    },
    methods:{
        closeModal() {
            this.saved = false;
            this.deleted = false;
        },
        async getPage() {    
            await this.$axios.get(`absences/get`,
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.absences = res.data.data
            })
            .catch(err => {
            })     
        },
        async sendData() {
        if (!this.v$.$invalid){
            const back = {
                title: this.name,
                type: this.type,
                color: this.color
            };
            try {
                const response = await axios.post("https://api-ems.mydev.kz/api/absences/create", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 200) {
                    // Добавить новую группу в массив после успешного ответа
                    // Сбросить поле ввода
                    this.saved = true;
                    this.name = '';
                    this.color = '';
                    this.type = '';
                    this.saved = true;
                    this.open = false,
                    this.openModal = false
                    this.getPage()
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.v$.$touch();
        }
    },
    deleteDate(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/absences/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        this.getPage()
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
    }
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
    .line{
        border: 1px solid #0000001A;
    }
    .absence{
        display: flex;
        width: 100%;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__add{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 22px 35px;
                div{
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                &__name{
                    input{
                        border: none;
                        border-bottom: 1px solid black !important;
                        padding: 10px;
                        outline: none;
                    }
                }
                &__button{
                    display: flex;
                    flex-direction: row !important;
                    align-items: center;
                    justify-content: space-between;
                    button{
                            padding: 8px 17px;
                            background: inherit;
                            color: #1965EA;
                            border: 1px solid #1965EA30;
                            border-radius: 10px;
                            width: fit-content;
                        }
                }
            }
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__right{
                    display: flex;
                    gap: 35px;
                    color: #1965EA;
                    font-weight: 600;
                    align-items: center;
                    &__date{
                        border: 1px solid #1965EA30;
                        padding: 7px 24px;
                        border-radius: 10px;
                        font-weight: 400 !important;
                    }
                }
            }
            &__name{
                    display: flex;
                    justify-content: space-between;
                    padding: 22px 35px;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background: #F0F0F0;
                    div{
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
                &__table{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__in{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        &__top{
                            display: flex;
                            justify-content: space-between;
                            padding: 0px 35px;
                            align-items: center;
                            div{
                                width: 25%;
                                display: flex;
                                justify-content: center;
                            }
                            &__change{
                                display: flex;
                                align-items: center;
                                padding: 0px !important;
                                justify-content: end !important;
                                color: #1965EA;
                                padding-right: 30px;
                            }
                            &__color{
                                &__in{
                                    width: 30px !important;
                                    height: 30px !important;
                                    border: 3px solid rgba(197, 182, 182, 0.767);
                                }
                            }
                        }
                    }
                }
        }
    }
</style>